import { containerless, customElement, bindable } from 'aurelia-framework';
import { LicenseInfo } from '@mui/x-license';
import { MUI_TABLE_KEY } from '../table/MuiTable/Table';
import { Provider } from 'react-redux';
import store from '../store/store';
import DashboardProvider from './components/DashboardStore';
import Dashboard from './Dashboard';
import { render, unmountComponentAtNode } from 'react-dom';

@customElement('sio-dashboard')
export class SioDashboard {
    @bindable reference;

    attached() {
        this.container = document.getElementById('sio-dashboard');
        this.reactRender();
    }

    reactRender() {

        if (this.container) {
            LicenseInfo.setLicenseKey(MUI_TABLE_KEY);
            render(
                <Provider store={store}>
                    <DashboardProvider value={{ object: this.reference }}>
                        <Dashboard />
                    </DashboardProvider>
                </Provider>,
                this.container
            );
        }
    }

    detached() {
        unmountComponentAtNode(this.container);
    }
}
