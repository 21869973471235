import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback,
} from 'react';
import { Box, CircularProgress } from '@mui/material';
import ModalListTable from '../../../../table/MuiTable/ModalListTable';
import { PANEL_TYPES, PANEL_TYPES_DATA } from '../useAddNewPanel';
import DynamicForm from '../../../../form/react/DynamicForm';

export const ListSteps = [
  { step: 1, label: 'dashboard.dashboardFrontend.panels.panelList.data' },
  { step: 2, label: 'dashboard.dashboardFrontend.panels.panelList.filters' },
];

const PanelListView = forwardRef(
  (
    {
      formData,
      setStepsList,
      handleFieldChange,
      setActiveStep,
      fields,
      activeStep,
      loading,
      onPanelLoad,
    },
    panelRef
  ) => {
    const [formConfig, setFormConfig] = useState(null);
    const formRef = useRef(null);
    const listFormData = formData?.[PANEL_TYPES.list.id];

    const onNextButtonClick = () => {
      setActiveStep((prevStep) => {
        if (prevStep === 1) {
          formRef?.current?.submit();
          return prevStep;
        }
        return prevStep + 1;
      });
    };

    useImperativeHandle(panelRef, () => ({
      nextButtonClick: () => onNextButtonClick(),
    }));

    const getOnLoad = async () => {
      const activePanel = PANEL_TYPES_DATA.find((p) => p.id == formData?.type);
      const data = await onPanelLoad(
        activePanel.modelId,
        listFormData?.modelIdentifier
          ? { modelIdentifier: listFormData?.modelIdentifier }
          : {}
      );
      setFormConfig(data?.data);
      setStepsList(ListSteps);
    };

    useEffect(() => {
      if (!formConfig) {
        getOnLoad();
      }
    }, [formConfig]);

    const onSortChange = (newSort) => {
      if (newSort?.length > 0) {
        handleFieldChange('', newSort, 'orderBy', PANEL_TYPES.list.id);
      }
    };

    const onFilterChange = (filters) => {
      if (filters?.items?.length > 0) {
        handleFieldChange('', filters, 'filters', PANEL_TYPES.list.id);
      }
    };

    const onPaginationModelChange = (data) => {
      if (data) {
        handleFieldChange('', data, 'limit', PANEL_TYPES.list.id);
      }
    };

    const onColumnOrderChange = (data) => {
      if (data) {
        handleFieldChange('', data, 'columnSortBy', PANEL_TYPES.list.id);
      }
    };

    const handleStep2Submit = useCallback((values) => {
      setActiveStep((p) => p + 1);
    }, []);

    const onDynamicFieldChange = ({
      property,
      value,
      newValues,
      setValues,
    }) => {
      const objectWithoutNulls = Object.fromEntries(
        Object.entries(newValues).filter(([_, value]) => value !== null)
      );
      handleFieldChange('', objectWithoutNulls, '', PANEL_TYPES.list.id, true);
      if (property === 'modelIdentifier') {
        if (Object.hasOwn(newValues, 'fields')) {
          if (newValues?.fields?.length > 0) {
            setValues((s) => ({ ...s, fields: [] }));
          }
        }
      }
    };

    const onConfigChange = (newConfig) => {
      setFormConfig(newConfig);
    };

    if (loading) {
      return (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      );
    }
    return fields.length > 0 ? (
      activeStep == 1 ? (
        <>
          <Box>
            {formConfig?.fields?.length > 0 && (
              <DynamicForm
                ref={formRef}
                config={formConfig}
                onSubmit={handleStep2Submit}
                hideSubmit={true}
                hiddenFields={['description', 'type']}
                defaultValues={listFormData}
                onFieldChange={onDynamicFieldChange}
                onConfigChange={onConfigChange}
              />
            )}
          </Box>
        </>
      ) : (
        <div style={{ height: '400px' }}>
          {listFormData?.fields?.length > 0 &&
            listFormData?.modelIdentifier && (
              <ModalListTable
                fields={listFormData?.fields.map((f) => f?.value || f)}
                modalName={listFormData?.modelIdentifier}
                onSortChange={onSortChange}
                onFilterChange={onFilterChange}
                onPaginationModelChange={onPaginationModelChange}
                onColumnOrderChange={onColumnOrderChange}
                limit={listFormData?.limit}
                filters={listFormData?.filters}
                orderBy={listFormData?.orderBy}
                columnSortBy={listFormData?.columnSortBy}
              />
            )}
        </div>
      )
    ) : (
      <></>
    );
  }
);

export default PanelListView;
