import React, { useRef } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Container,
  Modal,
  Box,
} from '@mui/material';
import Step0 from './Step0';
import PanelListView from './ListView/PanelListView';

import PanelStatisticView from './StatisticView/PanelStatisticView';
import useAddNewPanel, {
  PANEL_TYPES,
  PANEL_TYPES_DATA,
} from './useAddNewPanel';
import ModalBottomBar from './helpers/ModalBottomBar';
import { useAureliaI18n } from '../../../react-hooks/useAureliaI18n';

const AddNewPanel = ({ open, handleClose, defaultData, isEditing }) => {
  const { t } = useAureliaI18n();
  const handleNextCallback = (callbackFunction) => {
    if (typeof callbackFunction == 'function') {
      callbackFunction();
    }
  };
  const {
    activeStep,
    setActiveStep,
    stepsList,
    setStepsList,
    formData,
    setFormData,
    loading,
    fields,
    handleNext,
    handleBack,
    handleFieldChange,
    handleSubmit,
    onPanelLoad,
    panelForms,
  } = useAddNewPanel({ handleClose, defaultData, isEditing });

  const panelRef = useRef();

  const onNextClick =
    panelRef?.current?.nextButtonClick && activeStep !== stepsList.length
      ? panelRef.current.nextButtonClick
      : activeStep !== stepsList.length
      ? handleNext
      : handleSubmit;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="panel-wizard-modal"
      aria-describedby="panel-wizard-form"
    >
      <Box
        className="panel-wizard-modal"
        sx={{
          position: 'absolute',
          top: '60px',
          left: '50%',
          transform: 'translate(-50%, 0%)',
          width: '80%',
          maxWidth: 800,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 3,
          borderRadius: 2,
        }}
      >
        <div className="close-button" onClick={handleClose}>
          <i className="fa fa-close" />
        </div>
        <Container>
          <Typography sx={{ fontWeight: 'bold', fontSize: '2rem' }}>
            {t('dashboard.dashboardFrontend.panels.headline')}
          </Typography>
          <Stepper activeStep={activeStep} sx={{ mb: 4, mt: 4 }}>
            <Step>
              <StepLabel onClick={() => setActiveStep(0)}>Panel-Typ</StepLabel>
            </Step>
            {stepsList.map((step) => (
              <Step key={step.label} onClick={() => setActiveStep(step.step)}>
                <StepLabel>{t(step.label)}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box
            sx={{ mt: 4, mb: 4 }}
            style={{
              minHeight: '100px',
              maxHeight: '560px',
              overflowY: 'auto',
              paddingTop: '6px',
              paddingRight: '6px',
            }}
          >
            {activeStep === 0 ? (
              <Step0
                formData={formData}
                setFormData={setFormData}
                panelForms={panelForms}
                loading={loading}
              />
            ) : (
              <Box>
                {formData.type == PANEL_TYPES.list.id && (
                  <PanelListView
                    formData={formData}
                    setActiveStep={setActiveStep}
                    handleFieldChange={handleFieldChange}
                    fields={fields}
                    setStepsList={setStepsList}
                    activeStep={activeStep}
                    onPanelLoad={onPanelLoad}
                    loading={loading}
                    ref={panelRef}
                  />
                )}

                {formData.type == PANEL_TYPES.statistic.id && (
                  <PanelStatisticView
                    fields={fields}
                    setStepsList={setStepsList}
                    activeStep={activeStep}
                    loading={loading}
                    onPanelLoad={onPanelLoad}
                    setActiveStep={setActiveStep}
                    formData={formData}
                    handleFieldChange={handleFieldChange}
                    handleNextCallback={handleNextCallback}
                    ref={panelRef}
                  />
                )}
              </Box>
            )}
          </Box>
          <ModalBottomBar
            activeStep={activeStep}
            handleBack={handleBack}
            stepsList={stepsList}
            onNextClick={onNextClick}
            disabled={loading || (activeStep === 0 && !formData?.type)}
          />
        </Container>
      </Box>
    </Modal>
  );
};

export default AddNewPanel;
