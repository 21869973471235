import React, { useContext, useState } from 'react';
import {
  getDashboardFromId,
  isDashboardTemplate,
} from './functions/dashboard-general';
import { PANEL_TYPES, PANEL_TYPES_DATA } from './addNewPanel/useAddNewPanel';
import { DashboardContext } from './DashboardStore';
import AddNewPanel from './addNewPanel/AddNewPanel';
import { updateDashboard } from './functions/dashboard-api';
import { useFlash } from '../../react-hooks/useFlash';
import { useAureliaI18n } from '../../react-hooks/useAureliaI18n';
import { v4 as uuidv4 } from 'uuid';
import { selectUserContentLocale } from '../../store/store-slices/userSettingsSlice';
import { useSelector } from 'react-redux';

const ModalPanel = ({ panel, children }) => {
  const [openEditPanel, setOpenEditPanel] = useState(false);
  const [openEditPanelData, setOpenEditPanelData] = useState({});
  const [storeData, setStore] = useContext(DashboardContext);
  const { loading, activeDashboard, dashboards, editmode, object } =
    storeData || {};
  const flash = useFlash();
  const { t } = useAureliaI18n();
  const userContentLocale = useSelector(selectUserContentLocale);
  const isTemplate = isDashboardTemplate(object);

  const onPanelDelete = async (panel) => {
    const dashboard = getDashboardFromId(activeDashboard, storeData, editmode);
    const newPanelsData = dashboard.panels.filter((p) => p.id !== panel.id);
    const newDashboards = await updateDashboard(
      newPanelsData,
      dashboards,
      activeDashboard,
      editmode,
      isTemplate
    );
    setStore((s) => ({ ...s, editmodeDashboards: newDashboards }));
  };

  const onPanelEdit = async (panel) => {
    const panelType = PANEL_TYPES_DATA.find((p) => p.modelId === panel.modelId);
    if (panelType.id == PANEL_TYPES.list.id) {
      const data = {
        type: PANEL_TYPES.list.id,
        [PANEL_TYPES.list.id]: {
          title: panel.title,
          modelIdentifier: panel.modelIdentifier,
          fields: panel.fields,
          filters: panel?.filters,
          limit: panel?.limit,
          orderBy: panel?.orderBy,
          columnSortBy: panel?.columnSortBy,
        },
      };
      setOpenEditPanel(panel);
      setOpenEditPanelData(data);
    }
    if (panelType.id == PANEL_TYPES.statistic.id) {
      const data = {
        type: PANEL_TYPES.statistic.id,
        [PANEL_TYPES.statistic.id]: {
          type: panel.type,
          statsReport: panel.statsReport,
          title: panel.title,
          modelId: panel.modelId,
          dynamicDate: panel?.dynamicDate,
          data: panel.data,
        },
      };
      setOpenEditPanel(panel);
      setOpenEditPanelData(data);
    }
  };

  const onModalSubmit = async (newData) => {
    if (!newData?.modelId) {
      setOpenEditPanel(false);
      return false;
    }
    const dashboard = getDashboardFromId(activeDashboard, storeData, editmode);
    let newPanelsData = dashboard.panels.filter(
      (p) => p.id !== openEditPanel.id
    );
    const panel = dashboard.panels.find((p) => p.id == openEditPanel.id);
    const { i, ...position } = openEditPanel.position;
    newPanelsData = newPanelsData.concat({
      ...panel,
      ...newData,
      position: position || newData?.position,
    });
    const newDashboards = await updateDashboard(
      newPanelsData,
      dashboards,
      activeDashboard,
      editmode,
      isTemplate
    );
    setStore((s) => ({ ...s, editmodeDashboards: newDashboards }));
    setOpenEditPanel(false);
    setOpenEditPanelData({});
  };

  const onPanelDuplicate = async (panel) => {
    const dashboard = getDashboardFromId(activeDashboard, storeData, editmode);
    const { actions, id, position, ...newPanelData } = panel;
    const tempId = uuidv4();
    newPanelData.id = 'new-panel-' + tempId;
    newPanelData.position = {
      x: 0,
      y: 9999999999999,
      w: 6,
      h: 10,
      id: newPanelData.id,
    };
    const newDashboards = await updateDashboard(
      [...(dashboard.panels || []), newPanelData],
      dashboards,
      activeDashboard,
      editmode,
      isTemplate
    );
    setStore((s) => ({ ...s, editmodeDashboards: newDashboards }));
    flash.success('Dupliziertes Panel wird erstellt');
    setTimeout(() => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }, 400);
  };
  return (
    <>
      <div className="main-panel">
        <div className="panel-top">
          <h4 className={`${editmode ? 'draggable' : ''} panel-top-title`}>
            {panel?.title?.[userContentLocale] || 'de'}
          </h4>
          <div className="panel-top-edit-icons">
            {editmode && (
              <div
                onClick={() => onPanelDuplicate(panel)}
                title={t('dashboard.dashboardFrontend.duplicatePanel')}
              >
                <i className="fa fa-copy " />
              </div>
            )}
            {editmode && (
              <div
                onClick={() => onPanelEdit(panel)}
                title={t('dashboard.dashboardFrontend.editPanel')}
              >
                <i className="fa fa-edit" />
              </div>
            )}
            {editmode && (
              <div onClick={() => onPanelDelete(panel)} title={'Delete Panel'}>
                <i className="fa fa-close" />
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            flex: 1,
            minHeight: 0,
            overflow: 'auto',
          }}
          className="panel-body"
        >
          {children}
        </div>
      </div>
      {openEditPanel && (
        <AddNewPanel
          open={openEditPanel ? true : false}
          handleClose={onModalSubmit}
          defaultData={openEditPanelData}
          isEditing={true}
        />
      )}
    </>
  );
};

export default ModalPanel;
