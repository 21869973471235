import React from 'react';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Box,
  Grid,
  Card,
  CardContent,
  CircularProgress,
} from '@mui/material';
import { PANEL_TYPES_DATA } from './useAddNewPanel';
import { useAureliaI18n } from '../../../react-hooks/useAureliaI18n';

const Step0 = ({ loading, formData, setFormData, panelForms }) => {
  const { t } = useAureliaI18n();
  const handlePanelTypeChange = (e, id, url) => {
    e.preventDefault();
    e.stopPropagation();
    setFormData({ ...formData, type: id });
  };
  const getPanelData = (panel) => {
    return PANEL_TYPES_DATA.find((p) => p.modelId == panel.modelId);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <FormControl component="fieldset" fullWidth>
      <RadioGroup
        aria-label="panel-selection"
        name="panel-selection"
        value={formData.type || ''}
      >
        <Grid container spacing={2}>
          {panelForms.map((panel) => (
            <Grid
              item
              xs={12}
              sm={6}
              style={{ paddingBottom: '2px' }}
              key={getPanelData(panel)?.id}
            >
              <Card
                onClick={(e) =>
                  handlePanelTypeChange(e, getPanelData(panel)?.id, panel?.form)
                }
              >
                <Box
                  sx={{
                    height: 140,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'grey.200',
                  }}
                >
                  <i
                    className={`fa ${getPanelData(panel)?.icon}`}
                    style={{ fontSize: '70px', color: '#717171' }}
                  />
                </Box>
                <CardContent>
                  <FormControlLabel
                    value={getPanelData(panel)?.id}
                    control={<Radio />}
                    label={t(panel?.config?.name)}
                  />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </FormControl>
  );
};

export default Step0;
