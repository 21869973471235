import React from 'react';
import { Box, Button } from '@mui/material';
import { useAureliaI18n } from '../../../../react-hooks/useAureliaI18n';

const ModalBottomBar = ({
  activeStep,
  handleBack,
  stepsList,
  disabled,
  onNextClick,
}) => {
  const { t } = useAureliaI18n();
  return (
    <Box className="modal-bottom-bar" sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
      <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
        {t('dashboard.dashboardFrontend.backStep')}
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={onNextClick}
        disabled={disabled}
      >
        {activeStep !== stepsList.length
          ? t('dashboard.dashboardFrontend.nextStep')
          : t('dashboard.dashboardFrontend.save')}
      </Button>
    </Box>
  );
};

export default ModalBottomBar;
