import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useContext,
} from 'react';
import { getStatisticData } from '../functions/dashboard-api';
import GraphViewer from '../../../statistics/time-aggregation/graph-viewer';
import { CircularProgress, Box } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { DashboardContext } from '../DashboardStore';
import { getNewDatesRange } from '../functions/dashboard-general';
import TableViewer from '../../../statistics/time-aggregation/table-viewer';

const ModalStatistic = React.memo(({ panel }) => {
  const [graphData, setGraphData] = useState();
  const [loading, setLoading] = useState(false);
  const [height, setHeight] = useState(0);
  const graphRef = useRef(null);
  const graphId = useMemo(() => uuidv4(), []);
  const [storeData, setStore] = useContext(DashboardContext);
  const { editmode } = storeData || {};

  const getStatsData = async () => {
    setLoading(true);
    let dynamicData = { ...(panel?.data || {}) };
    if (panel?.dynamicDate && panel?.dynamicDate !== 'fixed') {
      dynamicData.range = getNewDatesRange(panel?.dynamicDate);
    }
    const stats = await getStatisticData(dynamicData);
    setGraphData(stats);
    setLoading(false);
  };
  useEffect(() => {
    if (!graphData) {
      getStatsData();
    }
    if (graphRef.current) {
      setHeight(graphRef.current?.clientHeight);
    }
  }, [editmode]);

  const makeData = useCallback(
    (data) => {
      let counter = 0;
      const calcSums = (data) => {
        if (!data || data instanceof Array) {
          return data ?? [];
        }
        const sums = new Map(
          graphData.columns.map((_, index) => [index, null])
        );
        Object.values(data).forEach((entry) => {
          calcSums(entry).forEach((value, index) => {
            if (null !== value) {
              let sum = sums.get(index) ?? {
                v: 0,
                c: graphData.data.baseCurrency,
              };

              sum.v += value.v ?? 0;

              if (value.err) {
                sum.err = value.err;
              }

              sums.set(index, sum);
            }
          });
        });
        data.__sums__ = Array.from(sums.values());
        counter = Object.keys(data).length - 1;
        Object.values(data.__sums__).forEach((value, index) => {
          if (null !== value) {
            const aggregation = graphData.columns[index].aggregation;
            if (aggregation === 'avg') {
              sums.set(index, value / counter);
            }
          }
        });
        return (data.__sums__ = Array.from(sums.values()));
      };
      calcSums(data);
    },
    [graphData]
  );
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (graphData === 'noAuth') {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        Sie haben keine Berechtigung, diesen Inhalt anzuzeigen.
      </Box>
    );
  }
  return (
    <div ref={graphRef} style={{ height: '100%' }}>
      {graphData && (
        <div className="graph-body" style={{ height: '100%' }}>
          {panel?.type == 'table' ? (
            <TableViewer
              data={graphData.data}
              columns={graphData.columns}
              labels={graphData.labels}
              grouping={graphData.grouping}
              showSum={0 < graphData?.grouping?.length}
              interval={panel?.data?.interval}
              diff={[false]}
            />
          ) : (
            <GraphViewer
              graphId={graphId}
              data={{ ...graphData.data, __sums__: makeData(graphData.data) }}
              grouping={graphData.grouping}
              labels={graphData.labels}
              columns={graphData.columns}
              interval={panel?.data?.interval}
              graphHeight={height}
              showSum={false}
              showGraphTitle={true}
              graphType={
                panel?.type == 'chart'
                  ? 'line'
                  : panel?.type == 'pie'
                  ? 'pie'
                  : panel?.type
              }
            />
          )}
        </div>
      )}
    </div>
  );
});

export default ModalStatistic;
