import React, { useEffect, useState, useContext, useCallback } from 'react';
import { ServicesContext } from '../welcome';
import { DashboardContext } from './components/DashboardStore';
import { getDashboardsList } from './components/functions/dashboard-api';
import PanelsLayout from './components/PanelsLayout';
import {
  changeActiveDashboard,
  getDashboardFromId,
  isDashboardDetailView,
  isDashboardTemplate,
} from './components/functions/dashboard-general';
import DashboardTopRow from './components/DashboardTopRow';
import ModalPanel from './components/ModalPanel';
import Skeleton from '@yisheng90/react-loading';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ModalListTable from '../table/MuiTable/ModalListTable';
import ModalStatistic from './components/ModalPanels/ModalStatistic';
import Welcome from './components/Welcome';
import { useClient } from '../react-hooks/useClient';

const Dashboard = () => {
  const client = useClient();
  const [storeData, setStore] = useContext(DashboardContext);
  const {
    loading,
    activeDashboard,
    dashboards,
    layout,
    editmodeDashboards,
    editmode,
    object,
  } = storeData || {};

  const isTemplate = isDashboardTemplate(object);
  const isDashboardDetail = isDashboardDetailView(object);

  const theme = createTheme({
    palette: {
      primary: {
        main: '#c45453',
      },
    },
    typography: {
      fontSize: 23,
      body1: {
        fontSize: 16,
      },
    },
  });

  const getDashboardsData = async () => {
    const dashboards = await getDashboardsList(client, setStore, isTemplate);
    if (dashboards.length > 0) {
      let preferredDashboard =
        dashboards?.find((d) => d?.isDefault == true)?.id || '';

      if (object?.id && (isDashboardDetail || isDashboardTemplate)) {
        preferredDashboard = object?.id || '';
      }

      changeActiveDashboard(
        preferredDashboard || dashboards?.[0]?.id || '',
        setStore
      );
    }
    setStore((s) => ({ ...s, loading: false }));
  };
  useEffect(() => {
    getDashboardsData();
  }, []);

  const activeDashboardData = getDashboardFromId(
    activeDashboard,
    storeData,
    editmode
  );
  useEffect(() => {
    if (activeDashboardData) {
      const layoutConfig = activeDashboardData.panels.map((panel, i) => {
        const position = panel.position;
        return {
          i: panel.id,
          x: position?.x,
          y: position?.y,
          w: position?.w || 6,
          h: position?.h || 10,
        };
      });
      setStore((s) => ({ ...s, layout: layoutConfig }));
    }
  }, [activeDashboardData?.panels, dashboards]);

  const onManualLayoutChange = async (newLayout) => {
    const layoutData = newLayout.map((l) => ({
      i: l.i,
      x: l.x,
      y: l.y,
      w: l.w,
      h: l.h,
    }));
    setStore((s) => ({ ...s, layout: layoutData }));
  };

  return loading ? (
    <div
      style={{
        background: 'white',
        padding: '2rem',
        display: 'flex',
        gap: '24px',
      }}
    >
      <Skeleton width={'50%'} height={'40rem'} rows={1} />
      <Skeleton width={'50%'} height={'40rem'} rows={1} />
    </div>
  ) : (
    <ThemeProvider theme={theme}>
      {!dashboards?.length > 0 ? (
        <Welcome />
      ) : (
        <div>
          <DashboardTopRow />
          <PanelsLayout
            layout={layout}
            draggableHandle={'.draggable'}
            setLayout={(nL) => setStore((s) => ({ ...s, layout: nL }))}
            onManualLayoutChange={onManualLayoutChange}
            editmode={editmode}
          >
            {activeDashboardData?.panels.map((panel, i) => (
              <div key={panel?.id}>
                {panel.modelId == 'dashboard/panel-list-view' ? (
                  <ModalPanel panel={panel} editmode={editmode}>
                    <ModalListTable
                      fields={panel?.fields}
                      orderBy={panel?.orderBy}
                      filters={panel?.filters}
                      modalName={panel?.modelIdentifier}
                      limit={panel?.limit}
                      columnSortBy={panel?.columnSortBy}
                    />
                  </ModalPanel>
                ) : panel.modelId == 'dashboard/panel-statistic-view' ? (
                  <ModalPanel panel={panel} editmode={editmode}>
                    <ModalStatistic panel={panel} />
                  </ModalPanel>
                ) : (
                  <></>
                )}
              </div>
            ))}
          </PanelsLayout>
        </div>
      )}
    </ThemeProvider>
  );
};

export default Dashboard;
