export const updatePanelData = (dashboard, panel, panelData, setStore) => {
    setStore((s) => ({
        ...(s || {}),
        dashboards: s?.dashboards.map((d) => ({
            ...(d || {}),
            panels: d?.panels.map((p) => ({
                ...(p || {}),
                panelData:
                    d.id === dashboard && p.id == panel
                        ? panelData
                        : p?.panelData,
            })),
        })),
    }));
};