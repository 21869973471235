import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { Box, CircularProgress, TextField, Grid } from '@mui/material';
import { useClient } from '../../../../react-hooks/useClient';
import DynamicForm from '../../../../form/react/DynamicForm';
import ChartType from './ChartType';
import ModalStatistic from '../../ModalPanels/ModalStatistic';
import { PANEL_TYPES, PANEL_TYPES_DATA } from '../useAddNewPanel';
import Autocomplete from '../../../../form/react/fields/Autocomplete';
import { processStatsFieldParams, statsField } from '../helpers/StatsField';
import { useAureliaI18n } from '../../../../react-hooks/useAureliaI18n';
import moment from 'moment';
import { getNewDatesRange } from '../../functions/dashboard-general';
import TranslatableField from '../../../../form/react/fields/TranslateableField';

export const stepsList = [
  {
    step: 1,
    label: 'dashboard.dashboardFrontend.panels.panelStatistic.chartType',
  },
  { step: 2, label: 'dashboard.dashboardFrontend.panels.panelStatistic.title' },
  {
    step: 3,
    label: 'dashboard.dashboardFrontend.panels.panelStatistic.fieldsEntry',
  },
  {
    step: 4,
    label: 'dashboard.dashboardFrontend.panels.panelStatistic.preview',
  },
];

const PanelStatisticView = forwardRef(
  (
    {
      formData,
      setStepsList,
      handleFieldChange,
      setActiveStep,
      activeStep,
      loading,
      onPanelLoad,
    },
    panelRef
  ) => {
    const client = useClient();
    const statisticFormData = formData[PANEL_TYPES.statistic.id];

    const [formConfig, setFormConfig] = useState({});
    const [statisticFormConfig, setStatisticFormConfig] = useState({});
    const [statsLoading, setStatsLoading] = useState(false);
    const [statParameters, setStatParameters] = useState(
      statisticFormData?.data || {}
    );
    const formRef = useRef();
    const { t } = useAureliaI18n();
    const getInitialStatsFieldConfig = async (payload = {}) => {
      const data = await client.post(
        'form/config/statistics/aggregate-report',
        payload
      );
      setFormConfig(data?.data);
      return data;
    };

    const getPanelFormConfig = async (activePanel) => {
      const data = await onPanelLoad(activePanel.modelId);
      setStatisticFormConfig(data?.data);
    };

    const changeDynamicDateRange = () => {
      const setValues = formRef?.current?.setDynamicFormValues;
      if (setValues) {
        setValues((s) => {
          let newData = { ...s };
          if (dynamicDateValue && dynamicDateValue !== 'fixed') {
            newData.range = getNewDatesRange(dynamicDateValue);
          }
          setStatParameters(newData);
          handleFieldChange('', newData, 'data', PANEL_TYPES.statistic.id);
          return newData;
        });
      }
    };
    useEffect(() => {
      const activePanel = PANEL_TYPES_DATA.find((p) => p.id == formData?.type);
      if (activeStep == 1 && !statisticFormData?.type) {
        handleFieldChange('', 'chart', 'type', PANEL_TYPES.statistic.id);
      }
      getPanelFormConfig(activePanel);
      setStepsList(stepsList);
      getInitialStatsFieldConfig(statisticFormData?.data || {});
      changeDynamicDateRange();
    }, []);

    const nextButtonClick = useCallback(() => {
      setActiveStep((prevStep) => {
        if (prevStep === 3) {
          formRef?.current?.submit();
          return prevStep;
        }
        return prevStep + 1;
      });
    }, []);

    useImperativeHandle(panelRef, () => ({
      nextButtonClick: nextButtonClick,
    }));

    const handleStep2Submit = useCallback((values) => {
      console.log('Form submitted with values:', values);
      handleFieldChange('', values, 'data', PANEL_TYPES.statistic.id);
      setActiveStep((p) => p + 1);
    }, []);

    const handleStatsReportChange = async (value) => {
      if (value?.id) {
        setStatsLoading(true);
        const reportData = await client.get(
          `statistics/stats-report/${value.id}`
        );

        const parameters = processStatsFieldParams(
          reportData?.parameters || {}
        );
        if (parameters) {
          setStatParameters(parameters);
          getInitialStatsFieldConfig(parameters);
        }
        setStatsLoading(false);
      }
      handleFieldChange('', value?.id, 'statsReport', PANEL_TYPES.statistic.id);
    };

    const onDynamicFieldChange = ({
      property,
      value,
      newValues,
      setValues,
    }) => {
      console.log({
        property,
        value,
        newValues,
        setValues,
      });

      if (property === 'model') {
        const data = {
          ...newValues,
        };
        if (Object.hasOwn(data, 'valueFields')) {
          data.valueFields = [];
        }
        if (Object.hasOwn(data, 'grouping')) {
          data.grouping = [];
        }
        setValues(data);
        // setStatParameters(data);
      } else {
        setStatParameters(newValues);
      }
    };

    const onDynamicDateRangeChange = (newVal) => {
      handleFieldChange('', newVal, 'dynamicDate', PANEL_TYPES.statistic.id);
    };
    const dynamicDateValue = statisticFormData?.dynamicDate;

    useEffect(() => {
      changeDynamicDateRange();
    }, [dynamicDateValue]);

    const dynamicDateConfig = statisticFormConfig?.fields?.find(
      (f) => f?.property == 'dynamicDate'
    );

    if (loading) {
      return (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      );
    }
    return activeStep == 1 ? (
      <ChartType
        formData={statisticFormData}
        handleFieldChange={handleFieldChange}
      />
    ) : activeStep == 2 ? (
      <Box>
        <Box sx={{ marginBottom: '16px' }}>
          <TranslatableField
            name="title"
            field={{
              label: 'dashboard.model.panel-interface.title',
              subType: 'text',
              required: true,
            }}
            onChange={(e) => {
              handleFieldChange('', e, 'title', PANEL_TYPES.statistic.id);
            }}
            value={statisticFormData?.title}
            error={!statisticFormData?.title}
          />
        </Box>
        <Box sx={{ mt: 1 }}>
          <Autocomplete
            field={statsField}
            multiple={false}
            value={
              statisticFormData?.statsReport?.id ||
              statisticFormData?.statsReport ||
              ''
            }
            onChange={handleStatsReportChange}
            label={t('dashboard.model.panel-statistic-view.statsReport')}
            renderCheckbox={false}
            required={false}
          />
          {statsLoading && <CircularProgress size={20} />}
        </Box>
      </Box>
    ) : activeStep == 3 ? (
      <>
        <Box>
          {formConfig?.fields?.length > 0 && (
            <>
              {dynamicDateConfig && (
                <Box sx={{ mt: 1, mb: 2 }}>
                  <Autocomplete
                    field={dynamicDateConfig}
                    multiple={false}
                    value={dynamicDateValue || 'fixed'}
                    onChange={onDynamicDateRangeChange}
                    label={t(dynamicDateConfig?.label)}
                    renderCheckbox={false}
                    required={false}
                  />
                </Box>
              )}
              <DynamicForm
                ref={formRef}
                config={formConfig}
                onSubmit={handleStep2Submit}
                hideSubmit={true}
                hiddenFields={[
                  'twoDimensional',
                  'excel',
                  'customConditions',
                  ...(statisticFormData?.dynamicDate === 'fixed' ||
                  !statisticFormData?.dynamicDate
                    ? []
                    : ['range']),
                ]}
                defaultValues={statParameters}
                onFieldChange={onDynamicFieldChange}
              />
            </>
          )}
        </Box>
      </>
    ) : (
      <div style={{ height: '350px' }}>
        {statisticFormData?.data && (
          <ModalStatistic
            panel={{
              data: statisticFormData?.data,
              type: statisticFormData?.type,
            }}
          />
        )}
      </div>
    );
  }
);

export default PanelStatisticView;
