import React, { useState, useEffect, useCallback } from 'react';
import GridLayout, { Responsive, WidthProvider } from 'react-grid-layout';
import _ from 'lodash';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const PanelsLayout = ({
  children,
  layout,
  setLayout,
  draggableHandle = false,
  onManualLayoutChange,
  editmode
}) => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState('lg');
  const [compactType, setCompactType] = useState('vertical');
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const onBreakpointChange = (breakpoint) => {
    setCurrentBreakpoint(breakpoint);
  };

  const isManualChange = (oldLayout, newLayout) => {
    if (oldLayout.length !== newLayout.length) return true;
    for (let i = 0; i < oldLayout.length; i++) {
      const oldItem = oldLayout[i];
      const newItem = newLayout[i];

      if (
        oldItem.i !== newItem.i ||
        oldItem.x !== newItem.x ||
        oldItem.y !== newItem.y ||
        oldItem.w !== newItem.w ||
        oldItem.h !== newItem.h
      ) {
        return true;
      }
    }

    return false;
  };

  const onLayoutChange = (l, layouts) => {
    const lgLayout = layouts.lg;
    if (isManualChange(layout, lgLayout)) {
      setLayout(lgLayout);
      onManualLayoutChange(lgLayout);
    }
  };

  const layoutConfig = {
    lg: layout,
  };
  return (
    <div className="sio-responsive-grid">
      <ResponsiveReactGridLayout
        className={'layout'}
        rowHeight={30}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        layouts={layoutConfig}
        onBreakpointChange={onBreakpointChange}
        onLayoutChange={onLayoutChange}
        measureBeforeMount={false}
        useCSSTransforms={mounted}
        compactType={compactType}
        preventCollision={!compactType}
        draggableHandle={draggableHandle}
        resizeHandles={
          editmode ? ['se', 'sw'] : []
        }
      >
        {children}
      </ResponsiveReactGridLayout>
    </div>
  );
};

export default PanelsLayout;
