import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useClient } from '../../../react-hooks/useClient';
import { ListSteps } from './ListView/PanelListView';
import { DashboardContext } from '../DashboardStore';
import { addNewPanel } from '../functions/dashboard-api';
import { v4 as uuidv4 } from 'uuid';

export const PANEL_TYPES = {
  list: {
    id: 'list',
    modelId: 'dashboard/panel-list-view',
    name: 'Table List View',
    icon: 'fa-table-list',
  },
  statistic: {
    id: 'statistic',
    modelId: 'dashboard/panel-statistic-view',
    name: 'Statistiken',
    icon: 'fa-chart-line',
  },
};
export const PANEL_TYPES_DATA = Object.values(PANEL_TYPES);
const useAddNewPanel = ({ handleClose, defaultData, isEditing = false }) => {
  const [activeStep, setActiveStep] = useState(isEditing ? 1 : 0);
  const [stepsList, setStepsList] = useState(ListSteps);
  const [panelForms, setPanelForms] = useState([]);
  const [formData, setFormData] = useState(
    defaultData ? defaultData : { type: 'list' }
  );
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const client = useClient();
  const [storeData, setStoreData] = useContext(DashboardContext);
  const { activeDashboard, dashboards, editmodeDashboards, editmode } =
    storeData || {};

  useEffect(() => {
    const getPanelForms = async () => {
      setLoading(true);
      const response = await client.get(`dashboards/form/config`);
      if (response?.panelForms?.length > 0) {
        setPanelForms(response?.panelForms);
      }
      setLoading(false);
    };
    getPanelForms();
  }, []);

  const handleNext = useCallback(() => {
    setActiveStep((prev) => prev + 1);
  }, [activeStep]);

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleFieldChange = (
    event,
    value,
    property,
    panelType,
    updatePanel = false
  ) => {
    const key = property || event?.target?.name;
    const newValue = value || event?.target?.value;
    if (panelType) {
      if (updatePanel) {
        setFormData((prev) => ({
          ...prev,
          [panelType]: { ...(prev[panelType] || {}), ...newValue },
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          [panelType]: { ...(prev[panelType] || {}), [key]: newValue },
        }));
      }
    } else {
      setFormData((prev) => ({ ...prev, [key]: newValue }));
    }
  };

  const handleListViewSubmit = async () => {
    const tempId = uuidv4();
    const position = { x: 0, y: 999999999999, w: 6, h: 10, id: tempId };
    const newData = {
      ...formData[PANEL_TYPES.list.id],
      fields: formData[PANEL_TYPES.list.id]?.fields.map((f) => f?.value || f),
      position,
      modelId: 'dashboard/panel-list-view',
      id: 'new-panel-' + tempId,
    };
    if (!isEditing) {
      const newDashboards = await addNewPanel(
        newData,
        editmodeDashboards,
        activeDashboard,
        editmode
      );
      setStoreData((prev) => ({ ...prev, editmodeDashboards: newDashboards }));
      handleClose();
    } else {
      handleClose(newData);
    }
  };

  const handleStatistikenViewSubmit = async () => {
    const tempId = uuidv4();
    const position = { x: 0, y: 999999999999, w: 6, h: 10, id: tempId };
    const newData = {
      ...formData[PANEL_TYPES.statistic.id],
      position,
      modelId: 'dashboard/panel-statistic-view',
      id: 'new-panel-' + tempId,
    };
    if (!isEditing) {
      const newDashboards = await addNewPanel(
        newData,
        editmodeDashboards,
        activeDashboard,
        editmode
      );
      setStoreData((prev) => ({ ...prev, editmodeDashboards: newDashboards }));
      handleClose();
    } else {
      handleClose(newData);
    }
  };

  const handleSubmit = async () => {
    if (formData.type == PANEL_TYPES.list.id) {
      await handleListViewSubmit();
    }

    if (formData.type == PANEL_TYPES.statistic.id) {
      await handleStatistikenViewSubmit();
    }
  };

  const onPanelLoad = useCallback(
    async (url, data = {}) => {
      setLoading(true);
      try {
        const response = await client.post(`form/config/${url}`, data);
        setFields(response?.data?.fields || []);
        return response;
      } catch (error) {
        console.error('Error loading panel:', error);
      } finally {
        setLoading(false);
      }
    },
    [client]
  );

  return {
    activeStep,
    setActiveStep,
    stepsList,
    setStepsList,
    formData,
    setFormData,
    loading,
    fields,
    handleNext,
    handleBack,
    handleFieldChange,
    handleSubmit,
    onPanelLoad,
    panelForms,
  };
};

export default useAddNewPanel;
