import React, { useCallback } from 'react';
import { ActionButton } from '../../action/react/action-button';
import { useUserClient } from '../../react-hooks/useUserClient';

export const addDashboardConifg = {
  type: 'view',
  viewId: 'dashboard/dashboard-editor-view',
  preset: 'new',
  reload: false,
  bulk: false,
  bulkOnly: false,
  hidden: false,
  permissions: {
    authentication: true,
    permissions: ['ROLE_USER'],
    conditions: [],
  },
  includeArchived: false,
  modal: true,
  label: 'Dashboard erstellen',
  buttonClass: 'btn-primary',
};

const Welcome = () => {
  const [userClient, hasRole] = useUserClient();
  const hasDashboardRole = useCallback(() => {
    const data = hasRole('manage-dashboard');
    return data;
  }, []);

  return (
    <div className="container-fluid text-center">
      <svg
        style={{ width: '10rem', height: '10rem', margin: '8rem auto' }}
        version="1.2"
        viewBox="0 0 21000 21000"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="rgba(196, 84, 83, 1.00)"
          stroke="rgba(196, 84, 83, 1.00)"
          d="M 19329,142 L 20629,142 20683,198 20629,254 20358,364 16946,698 12071,1922 8116,3704 7033,4316 6816,4483 6708,4539 6653,4539 4271,6208 3079,7377 2700,7878 2375,8545 2212,8991 2212,9602 2266,9826 2429,10159 2537,10327 3133,10939 4596,12052 6221,12887 8442,13666 9850,13999 9959,14111 9904,14167 9092,14167 5516,13610 3188,12887 2103,12329 1562,11940 804,11161 534,10771 207,9882 100,9380 154,8713 262,8323 534,7766 913,7210 2646,5429 4054,4372 5408,3537 9796,1756 13371,865 19329,142 Z M 11312,6877 L 15862,7544 17813,8156 18733,8601 19437,9047 20033,9602 20033,9658 20249,9937 20467,10159 20737,10717 20900,11272 20900,12275 20629,13054 20249,13666 20033,13888 19925,14055 19925,14111 19870,14167 19816,14167 19545,14445 19545,14501 18950,15112 16296,17116 15266,17728 12666,18842 10391,19565 6221,20455 1292,20900 425,20900 370,20844 425,20790 479,20790 4433,20233 9146,19008 13262,17172 13371,17060 13587,16949 13642,16949 15808,15558 17325,14333 18137,13444 18408,13054 18841,11996 18841,11440 18787,11217 18625,10883 18517,10717 17813,9992 17054,9380 16296,8879 15104,8267 13045,7488 11149,7043 11096,6987 11149,6931 11312,6877 Z"
        />
      </svg>
      <h1>Herzlich willkommen bei SIO!</h1>
      {hasDashboardRole() && (
        <div style={{ marginTop: '30px' }}>
          <ActionButton
            config={addDashboardConifg}
            context={{}}
            onSubmitted={() => window.location.reload()}
          />
        </div>
      )}
    </div>
  );
};

export default Welcome;
