import moment from 'moment';

export const changeActiveDashboard = (dashboard, setStore) => {
    setStore((s) => ({ ...s, activeDashboard: dashboard }));
};

export const getDashboardFromId = (id, store, editMode) => {
    return editMode
        ? store?.editmodeDashboards?.find((d) => d?.id === id)
        : store?.dashboards?.find((d) => d?.id === id);
};

export const isDashboardTemplate = (object) =>
    object?.modelId === 'dashboard/dashboard-template';

export const isDashboardDetailView = (object) =>
    object?.modelId === 'dashboard/dashboard';

export const getNewDatesRange = (dynamicDateValue) => {
    let range;
    if (dynamicDateValue === 'today') {
        range = [
            moment().startOf('day').format('YYYY-MM-DDTHH:mm:ssZ'),
            moment().endOf('day').format('YYYY-MM-DDTHH:mm:ssZ'),
        ];
    } else if (dynamicDateValue === 'this-week') {
        range = [
            moment().startOf('week').format('YYYY-MM-DDTHH:mm:ssZ'),
            moment().endOf('week').format('YYYY-MM-DDTHH:mm:ssZ'),
        ];
    } else if (dynamicDateValue === 'biweekly') {
        range = [
            moment().startOf('week').format('YYYY-MM-DDTHH:mm:ssZ'),
            moment()
                .add(1, 'week')
                .endOf('week')
                .format('YYYY-MM-DDTHH:mm:ssZ'),
        ];
    } else if (dynamicDateValue === 'monthly') {
        range = [
            moment().startOf('month').format('YYYY-MM-DDTHH:mm:ssZ'),
            moment().endOf('month').format('YYYY-MM-DDTHH:mm:ssZ'),
        ];
    } else if (dynamicDateValue === 'quarterly') {
        range = [
            moment().startOf('quarter').format('YYYY-MM-DDTHH:mm:ssZ'),
            moment().endOf('quarter').format('YYYY-MM-DDTHH:mm:ssZ'),
        ];
    } else if (dynamicDateValue === 'semiannually') {
        let startOfHalfYear =
            moment().quarter() <= 2
                ? moment().startOf('year')
                : moment().month(6).startOf('month');
        let endOfHalfYear =
            moment().quarter() <= 2
                ? moment().month(5).endOf('month')
                : moment().endOf('year');
        range = [
            startOfHalfYear.format('YYYY-MM-DDTHH:mm:ssZ'),
            endOfHalfYear.format('YYYY-MM-DDTHH:mm:ssZ'),
        ];
    } else if (dynamicDateValue === 'annually') {
        range = [
            moment().startOf('year').format('YYYY-MM-DDTHH:mm:ssZ'),
            moment().endOf('year').format('YYYY-MM-DDTHH:mm:ssZ'),
        ];
    }
    return range;
};
