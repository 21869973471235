import { useClient } from '../../../react-hooks/useClient';
import { updatePanelData } from './dashboard-store';

export const getDashboardsList = async (client, setStore, isTemplate) => {
    setStore((s) => ({ ...s, loading: true }));
    let url = !isTemplate
        ? 'dashboard/dashboard'
        : 'dashboard/dashboard-template';

    const data = await client.get(url);

    const newItems = data?.items?.map((item) => ({
        ...item,
        panels: item.panels.map((p) => ({
            ...p,
            ...(p.type === 'list' && {
                fields: p.fields.map((f) =>
                    f.replace(`${p.modelIdentifier},`, '')
                ),
            }),
        })),
    }));

    setStore((s) => ({ ...s, dashboards: newItems }));
    return data?.items;
};

export const muiToMongoFilter = (muiFilter) => {
    let conditions = muiFilter.items.map((item) => {
        switch (item.operator) {
            case 'contains':
                return { [item.field]: { $regex: item.value, $options: 'i' } };
            // Add more cases here for different operators if needed
            default:
                throw new Error(`Unsupported operator: ${item.operator}`);
        }
    });

    let mongoQuery = {
        $and: [
            {
                $and: [{ archived: { $ne: true } }, ...conditions],
            },
        ],
    };

    return mongoQuery;
};
export const getPanelData = async ({
    client,
    panel,
    offset = 0,
    limit = 10,
    conditions,
    embeds = [],
    sort = [],
}) => {
    const params = {
        offset: offset,
        limit: limit,
        conditions: JSON.stringify(
            conditions ||
                {
                    // $and: [{ $and: [{ archived: { $ne: true } }] }],
                }
        ),
        loadCount: true,
    };

    const searchParams = new URLSearchParams(params);

    if (embeds.length > 0) {
        embeds.forEach((embed) => {
            searchParams.append('embeds[]', embed?.embeds);
        });
    }

    if (sort.length > 0) {
        sort.forEach((s, i) => {
            searchParams.append(`sort[${i}][]`, s?.field);
            searchParams.append(`sort[${i}][]`, s?.sort?.toUpperCase());
        });
    }

    const data = await client.get(`${panel}?${searchParams.toString()}`);
    return data;
};

export const fetchPanelConfig = async ({ client, panel }) => {
    const data = await client.get(`${panel}/list`);
    return data;
};

export const fetchPanelData = async ({
    client,
    panel,
    offset,
    limit,
    conditions,
    embeds,
    sort = [],
}) => {
    return await getPanelData({
        client,
        panel,
        offset,
        limit,
        conditions,
        embeds,
        sort,
    });
};

export const getAndUpdatePanelData = async (
    panel,
    dashboard,
    setStore,
    client
) => {
    const panelData = await getPanelData({
        client,
        panel: panel.modelIdentifier,
    });
    updatePanelData(dashboard.id, panel.id, panelData, setStore);
};

export const addNewPanel = async (
    newPanel,
    dashboards,
    activeDashboard,
    editmode
) => {
    const dashboard = dashboards.find((d) => d.id == activeDashboard);
    if (dashboard) {
        const makePanels = dashboard.panels.map((panel) => {
            const { actions, ...np } = panel;
            return np;
        });
        makePanels.push(newPanel);
        const data = await updateDashboard(
            makePanels,
            dashboards,
            activeDashboard,
            editmode
        );
        return data;
    }
};

export const updateDashboard = async (
    newPanels,
    dashboards,
    activeDashboard,
    editmode,
    isTemplate
) => {
    const dashboard = dashboards.find((d) => d.id == activeDashboard);
    if (dashboard) {
        const makePanels = newPanels.map((panel) => {
            const { actions, ...np } = panel;
            return np;
        });
        const data = {
            dashboardName: dashboard.dashboardName,
            viewPermissions: dashboard.viewPermissions || [],
            panels: makePanels,
        };

        if (isTemplate) {
            delete data.viewPermissions;
        }

        if (editmode) {
            const newBoard = { ...dashboard, panels: makePanels };
            return dashboards
                .filter((d) => d.id !== activeDashboard)
                .concat({
                    ...newBoard,
                });
        }
        const newData = await relinkDashboard(dashboard, data, isTemplate);
        const newDashboard = dashboards
            .filter((d) => d.id !== activeDashboard)
            .concat({
                ...newData?.data,
                actions: dashboard?.actions,
                isDefault: dashboard?.isDefault,
            });

        return newDashboard;
    }
};

const relinkDashboard = async (dashboard, data, isTemplate) => {
    const client = useClient();
    const url = isTemplate
        ? 'dashboard/update-dashboard-template-panels/'
        : 'dashboard/update-dashboard/';
    const newData = await client.put(`${url}${dashboard.id}`, data);
    return newData;
};

export const getStatisticData = async (data) => {
    try {
        if (!data) {
            return [];
        }
        const client = useClient();
        const newData = await client.post(`statistics-aggregate-report`, data);
        return newData?.data;
    } catch (error) {
        return "noAuth";
    }
};
