import React, { createContext, useState, useEffect } from 'react';

export const DashboardContext = createContext();

// state
// { loading: true
// dashboards
// config:{activeDashboard}
// panelLoading
// }

const DashboardProvider = ({ children, value }) => {
  const [contextProviderValue, setContextProviderValue] = useState(value || {});


  return (
    <DashboardContext.Provider
      value={[contextProviderValue, setContextProviderValue]}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;

export const updateStore = (store, key, value) => {
  const keys = key.split('.');
  const updatedStore = { ...store };
  let current = updatedStore;

  for (let i = 0; i < keys.length - 1; i++) {
    const k = keys[i];
    if (!(k in current)) {
      current[k] = {};
    }
    current = current[k];
  }
  current[keys[keys.length - 1]] = value;

  return updatedStore;
};
