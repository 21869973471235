import React, { useEffect, useState } from 'react';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Box,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import { PANEL_TYPES } from '../useAddNewPanel';
import { useAureliaI18n } from '../../../../react-hooks/useAureliaI18n';

export const chartTypes = [
  {
    label: 'dashboard.model.panel-statistic-view.type.choice.chart',
    value: 'chart',
    image: '',
    icon: 'fa-line-chart',
  },
  {
    label: 'dashboard.model.panel-statistic-view.type.choice.pie',
    value: 'pie',
    image: '',
    icon: 'fa-pie-chart',
  },
  {
    label: 'dashboard.model.panel-statistic-view.type.choice.bar',
    value: 'bar',
    image: '',
    icon: 'fa-bar-chart',
  },
  {
    label: 'dashboard.model.panel-statistic-view.type.choice.area',
    value: 'area',
    image: '',
    icon: 'fa-area-chart',
  },
  {
    label: 'dashboard.model.panel-statistic-view.type.choice.table',
    value: 'table',
    image: '',
    icon: 'fa-table',
  },
];

const ChartType = ({ formData, handleFieldChange }) => {
  const handleChartTypeChange = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
    handleFieldChange('', value, 'type', PANEL_TYPES.statistic.id);
  };
  const { t } = useAureliaI18n();
  return (
    <FormControl component="fieldset" fullWidth>
      <RadioGroup
        aria-label="chart-selection"
        name="chart-selection"
        value={formData?.type || ''}
      >
        <Grid container spacing={2}>
          {chartTypes.map((chart) => (
            <Grid
              item
              xs={12}
              sm={6}
              style={{ paddingBottom: '2px' }}
              key={chart.value}
            >
              <Card onClick={(e) => handleChartTypeChange(e, chart.value)}>
                <Box
                  sx={{
                    height: 100,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'grey.200',
                  }}
                >
                  <i
                    className={`fa ${chart?.icon}`}
                    style={{ fontSize: '50px', color: '#717171' }}
                  />
                </Box>
                <CardContent >
                  <FormControlLabel
                    value={chart.value}
                    control={<Radio />}
                    label={t(chart.label)}
                  />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </FormControl>
  );
};

export default ChartType;
