import {
    dateMapper,
    groupedFields,
    rangesShortcuts,
} from '../../../../statistics/statistics';

export const statsField = {
    property: 'statsReport',
    type: 'choice',
    label: 'statistics.stats-report',
    constraints: [],
    hidden: false,
    showIf: [],
    keepValueIfHidden: false,
    doNotSetDefault: false,
    context: false,
    implementations: [],
    required: false,
    mapped: true,
    fullPage: false,
    subType: 'simple',
    multiple: false,
    sortable: false,
    expanded: false,
    choices: [],
    allowAdd: false,
    allowDelete: false,
    hideCollectionButtons: false,
    modelId: 'statistics/stats-report',
    objectChoices: false,
    conditions: [],
    factor: 1,
    scale: 2,
    person: false,
    readonly: false,
    options: [],
    selects: [],
    hideCreateAction: false,
    hideSelectFromTable: false,
    lengthIndicator: false,
    translateChoiceLabel: true,
    columnLabels: [],
    public: false,
    layoutColumn: 0,
    geocoding: true,
    singleLine: false,
    trim: true,
    convertToDate: false,
    availableFields: [],
    isAppendMode: false,
    matchSearchFromStart: false,
    filteredCountries: [],
};

export const processStatsFieldParams = (params) => {
    const newParams = { ...(params || {}) };
    if (params.range) {
        const range = rangesShortcuts.find((r) => r[0] === params.range);
        if (range) {
            newParams.range = JSON.parse(range[1]);
        }
    } else if (params.from) {
        newParams.range = dateMapper([params.from, params.to]);
    } else {
        newParams.range = JSON.parse(
            rangesShortcuts.find(([id]) => 'last-month' === id)[1]
        );
    }

    newParams.interval = params.interval || '1y';

    for (let field of groupedFields) {
        if (params.hasOwnProperty(field)) {
            newParams[field] = params[field].split(',');
        }
    }

    return newParams;
};
