import React, { useContext, useState, useCallback, useEffect } from 'react';
import { DashboardContext } from './DashboardStore';
import {
  getDashboardFromId,
  isDashboardTemplate,
} from './functions/dashboard-general';
import AddNewPanel from './addNewPanel/AddNewPanel';
import { Button, Tooltip, Typography } from '@mui/material';
import { useUserClient } from '../../react-hooks/useUserClient';
import { Container } from 'aurelia-framework';
import { StandardActions } from '../../action/standard-actions';
import { addDashboardConifg } from './Welcome';
import { useAureliaI18n } from '../../react-hooks/useAureliaI18n';
import { updateDashboard } from './functions/dashboard-api';
import { useFlash } from '../../react-hooks/useFlash';
import { selectUserContentLocale } from '../../store/store-slices/userSettingsSlice';
import { useSelector } from 'react-redux';

const DashboardTopRow = () => {
  const [
    { activeDashboard, editmode, layout, object, ...storeData },
    setStore,
  ] = useContext(DashboardContext);
  const dashboard = getDashboardFromId(activeDashboard, storeData);
  const [menuOpen, setMenuOpen] = useState(false);
  const [openAddNewPanel, setOpenAddNewPanel] = useState(false);
  const { dashboards } = storeData;
  const actions = Container.instance.get(StandardActions);
  const { t } = useAureliaI18n();
  const contentLocale = useSelector(selectUserContentLocale) || 'de';
  const unActiveDashboards = dashboards?.filter(
    (d) => d.id !== activeDashboard
  );
  const [userClient, hasRole] = useUserClient();
  const hasDashboardRole = useCallback(() => {
    const data = hasRole('manage-dashboard');
    return data;
  }, []);
  const flash = useFlash();
  const isTemplate = isDashboardTemplate(object);

  useEffect(() => {
    function handleClick(event) {
      if (event.target.closest('.dashbaords-list-menu') === null) {
        setMenuOpen(false);
      }
    }
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const editAndSaveButtonClick = async () => {
    if (editmode) {
      // Save button click
      const dashboard = getDashboardFromId(
        activeDashboard,
        storeData,
        editmode
      );
      const duplicatedPanels = dashboard?.panels?.filter((p) =>
        p?.id?.includes('new-panel')
      );
      const cleanedDuplicatedPanels = duplicatedPanels.map((panel) => {
        const { id, i, ...rest } = panel;
        const { id: pID, ...newPosition } = panel.position;
        return {
          ...rest,
          position: {
            ...(layout.find((l) => l.i === panel.id) || newPosition),
          },
        };
      });
      const nonDuplicatedPanels = dashboard?.panels
        ?.filter((p) => !p?.id?.includes('new-panel'))
        .map((p) => ({
          ...p,
          position: { ...layout.find((l) => l.i === p.id), id: p?.id },
        }));
      const combinedPanels = [
        ...cleanedDuplicatedPanels,
        ...nonDuplicatedPanels,
      ];

      const newDashboards = await updateDashboard(
        combinedPanels,
        dashboards,
        activeDashboard,
        undefined,
        isTemplate
      );
      setStore((s) => ({ ...s, dashboards: newDashboards }));
      flash.success('Dashboard updated');
    } else {
      // Edit button click
      setStore((s) => ({ ...s, editmodeDashboards: s.dashboards }));
    }
    setStore((s) => ({ ...s, editmode: !editmode }));
  };
  const menuItemClick = (dashboard) => {
    setStore((s) => ({ ...s, activeDashboard: dashboard.id, editmode: false }));
    setMenuOpen(false);
  };

  const onMenuTitleClick = () => {
    setMenuOpen(!menuOpen);
  };

  const openAddNewPanelHandler = () => {
    setOpenAddNewPanel(!openAddNewPanel);
  };

  const addNewDashboard = async () => {
    try {
      const result = await actions.getAction(addDashboardConifg, {}).action();
      if (result?.id) {
        setStore((s) => ({
          ...s,
          dashboards: [...(s?.dashboards || []), result],
          activeDashboard: result.id,
        }));
      }
    } catch (e) {
    } finally {
      setMenuOpen(false);
    }
  };

  const onEditDashboardClick = async (dashboard, e) => {
    e.preventDefault();
    e.stopPropagation();
    const editAction = dashboard?.actions?.find(
      (d) => d?.viewId == 'dashboard/dashboard-edit-editor-view'
    );
    if (editAction) {
      const result = await actions
        .getAction(
          { ...editAction, type: 'view' },
          {
            modelId: dashboard.modelId,
            id: dashboard.id,
          }
        )
        .action();
      if (result?.id) {
        setStore((s) => ({
          ...s,
          dashboards: [
            ...(s?.dashboards?.filter((t) => t?.id !== dashboard?.id) || []),
            { ...result, actions: dashboard?.actions },
          ],
        }));
      }
    }
  };

  const setAsDefaultDashboard = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const action = dashboard?.actions?.find(
      (d) => d?.workflowId == 'dashboard/user-set-default-dashboard'
    );
    if (action) {
      const result = await actions
        .getAction(action, {
          modelId: dashboard.modelId,
          id: dashboard.id,
        })
        .action();
      if (result?.[0]?.user?.id) {
        setStore((s) => ({
          ...s,
          dashboards:
            s?.dashboards?.map((d) => ({
              ...d,
              isDefault: d?.id == dashboard?.id ? true : false,
            })) || [],
        }));
      }
    }
  };
  return (
    <div className="dashboard-top-row">
      <div className="dashboard-top-row_menu">
        <div className="dashboard-top-row_details">
          <div style={{ display: 'flex', gap: '20px' }}>
            {!isTemplate && (
              <div onClick={onMenuTitleClick} className="dashbaords-list-menu">
                <span className="dashboard-top-row_title">
                  {dashboard?.dashboardName?.[contentLocale]}
                </span>
                {
                  <span>
                    <i
                      style={{ marginLeft: '6px' }}
                      className={`fa  ml-2 ${
                        menuOpen ? 'fa-angle-up' : 'fa-angle-down'
                      }`}
                    ></i>
                  </span>
                }
              </div>
            )}
          </div>
          <div className="dashboard-top-row_buttons">
            {!isTemplate && editmode && (
              <div>
                <Tooltip
                  title={
                    <Typography sx={{ p: 1 }}>
                      {t('dashboard.dashboardFrontend.editDashboard')}
                    </Typography>
                  }
                  arrow
                >
                  <div
                    onClick={(e) => onEditDashboardClick(dashboard, e)}
                    className="outlined-icon"
                  >
                    <i className="fa  fa-pencil" />
                  </div>
                </Tooltip>
              </div>
            )}
            {!isTemplate &&
              editmode &&
              dashboard?.isDefault == false &&
              dashboards?.length > 0 && (
                <div>
                  <Tooltip
                    title={
                      <Typography sx={{ p: 1 }}>
                        Zu meinem Standard-Dashboard machen
                      </Typography>
                    }
                    arrow
                  >
                    <div
                      onClick={setAsDefaultDashboard}
                      className="outlined-icon hover-green"
                    >
                      <i className="fa  fa-check-circle" />
                    </div>
                  </Tooltip>
                </div>
              )}
            {editmode && (
              <div>
                <Button
                  variant="outlined"
                  startIcon={<i className="fa  fa-plus" />}
                  onClick={openAddNewPanelHandler}
                >
                  {t('dashboard.dashboardFrontend.newPanel')}
                </Button>
              </div>
            )}
            {hasDashboardRole() && editmode && (
              <div>
                <Button
                  variant={'outlined'}
                  onClick={() => {
                    setStore((s) => ({ ...s, editmode: false }));
                  }}
                >
                  {t('Cancel')}
                </Button>
              </div>
            )}
            {hasDashboardRole() && (
              <div>
                <Button
                  variant={editmode ? 'contained' : 'outlined'}
                  startIcon={
                    <i className={`fa ${editmode ? 'fa-save' : 'fa-pencil'}`} />
                  }
                  onClick={editAndSaveButtonClick}
                >
                  {editmode
                    ? t('dashboard.dashboardFrontend.save')
                    : t('dashboard.dashboardFrontend.edit')}
                </Button>
              </div>
            )}
          </div>
        </div>
        {menuOpen && (
          <div className="dashbaords-list-menu">
            <div className="dashboards-menu">
              {unActiveDashboards.map((dash) => (
                <div
                  key={dash.id}
                  className="dashboards-menu-item"
                  onClick={() => menuItemClick(dash)}
                >
                  <div>{dash?.dashboardName?.[contentLocale]}</div>
                  <div
                    onClick={(e) => onEditDashboardClick(dash, e)}
                    className="dash-icons"
                    title="Edit Dashboard"
                  >
                    <i className="fa fa-pencil"></i>
                  </div>
                </div>
              ))}
              {hasDashboardRole() && (
                <div className="dashboards-menu-item" onClick={addNewDashboard}>
                  <span>
                    <i
                      className="fa fa-plus-circle"
                      style={{ color: 'green', marginRight: '12px' }}
                    />
                  </span>
                  {t('dashboard.dashboardFrontend.addDashboard')}
                </div>
              )}
            </div>
          </div>
        )}
        {openAddNewPanel && (
          <AddNewPanel
            open={openAddNewPanel}
            handleClose={() => setOpenAddNewPanel(false)}
          />
        )}
      </div>
    </div>
  );
};

export default DashboardTopRow;
